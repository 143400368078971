import { useEffect, useState } from 'react';

const formatter = (price: number) => {
  const data = price.toString().split('.');
  return data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (data[1] ? '.' + data[1] : '');
};

let need = 0;
let webApp: any = null;
let initData: any = null;
// const api = 'http://localhost:4100/v1';
const api = 'https://api.fastix.biz/v1';

const App = () => {
  const [page, setPage] = useState('none');
  const [receiver, setReceiver] = useState('');
  const [tab, setTab] = useState('Bonus');
  const [wallets, setWallets] = useState<undefined | []>(undefined);
  const [user, setUser] = useState<any>(null);
  const [current, setCurrent] = useState(0);
  const [achieve, setAchieve] = useState(0);
  const [array, setArray] = useState<{ x: number; y: number }[]>([]);

  const taptap = (e: any) => {
    if (current <= 0) {
      return;
    }

    setCurrent(current - 1);
    setAchieve(achieve + 1);
    setArray((array) => [...array, { x: e.clientX, y: e.clientY }]);

    need += 1;
  };

  const listFriends = () => {
    const rows: any = [];

    user?.invites?.forEach((e: any, i: number) => {
      rows.push(<i key={i}>{e}</i>);
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any friend</div>;
    }

    return rows;
  };

  const listTrans = () => {
    const rows: any = [];

    wallets?.forEach((e, i) => {
      rows.push(<i key={i}></i>);
    });

    if (rows.length === 0) {
      return <div className='infoAir empty'>There is no any transactions</div>;
    }

    return rows;
  };

  const listShow = () => {
    const rows: any = [];

    array.forEach((e, i) => {
      rows.push(
        <i key={i} style={{ left: e.x, top: e.y - 40 }}>
          1
        </i>,
      );
    });

    return rows;
  };

  const reloading = async () => {
    try {
      if (need > 0) {
        fetch(`${api}/user/add/`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: 'telegram-' + initData,
          },
          body: JSON.stringify({
            value: need,
          }),
        })
          .then((_e) => {})
          .catch((_e) => {});

        need = 0;
      } else {
        const res = await fetch(`${api}/user`, {
          headers: {
            'content-type': 'application/json',
            authorization: 'telegram-' + initData,
          },
        });

        const data = (await res.json()).data;

        setUser(data);
        setCurrent(data.current);
        setAchieve(data.achieve);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const tgWebAppStartParam = urlParams.get('tgWebAppStartParam');

      const res = await fetch(`${api}/user/telegram`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: 'telegram-' + initData,
        },
        body: JSON.stringify({
          referral: tgWebAppStartParam || null,
        }),
      });

      const data = (await res.json()).data;

      setUser(data);
      setCurrent(data.current);
      setAchieve(data.achieve);

      // await getWallets();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let timer: any = null;

    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      webApp = app.initDataUnsafe;
      initData = app.initData;
      // webApp = {
      //   chat_instance: 1,
      //   user: {
      //     hash: 'b',
      //     id: 1,
      //     first_name: 'j',
      //     last_name: 'k',
      //   },
      // };

      if (!webApp.chat_instance) {
        alert('Error');
      } else {
        init()
          .then(() => {
            timer = setInterval(() => {
              reloading();
            }, 2000);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setArray([]);
  }, [tab]);

  return user ? (
    <div className='airFlex'>
      <div className='tabs'>
        <span className={tab === 'Bonus' ? 'active' : ''} onClick={() => setTab('Bonus')}>
          Bonus
        </span>
        <span className={tab === 'Referral' ? 'active' : ''} onClick={() => setTab('Referral')}>
          Referral
        </span>
      </div>
      {page === 'newWallet' ? (
        <></>
      ) : tab === 'Bonus' ? (
        <>
          <p className='currentAir'>Your Total Bonus: {formatter(achieve || 0)}</p>
          <p className='infoAir'>
            Tap Tap on Diamond and get FREE bonus!
            <br />
            Available: {formatter(current || 0)}
          </p>
          <div className='uwalletClicker' onClick={(e) => taptap(e)}>
            {listShow()}
          </div>
        </>
      ) : (
        <>
          <p className='infoAir'>
            Share your invite link with your friends and get more bonus!
            <br />
            <br />
            <a target='_blank' href={'https://t.me/FastixBizBot/Fastix?startapp=' + webApp?.user?.id + ''}>
              https://t.me/FastixBizBot/Fastix?startapp={webApp?.user?.id}
            </a>
          </p>
          <p className='infoAir'>
            Your friends:
            <br />
            <div className='listWallet'>{listFriends()}</div>
          </p>
        </>
      )}
    </div>
  ) : (
    <div className='airFlex'>Loading...</div>
  );
};

export default App;
